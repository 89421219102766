#popular-products {
    padding: rem(40px, 0);
    .title {
        @extend .font-primary-1;
        position: relative;
        font-size: rem(18px);
        text-transform: uppercase;
        color:$color_secondary_light;
        padding-bottom: rem(10px);
        border-bottom: rem(2px) solid $color_secondary_light;
        margin-bottom: rem(15px);
        &:before {
            content: '';
            position: absolute;
            width: rem(210px);
            height: rem(2px);
            background: $color_primary;
            bottom: rem(-2px);
        }
    }
    .swiper {
        width: 100%;
        height: 100%;
        .swiper-slide {
            text-align: center;
            font-size: 18px;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;

            figure {
                display: inline-block;
                .img-cont {
                    height: 280px;
                    vertical-align: middle;
                    text-align: center;
                    display: table-cell;

                    @include media-breakpoint-only(lg) {
                        height: 220px;
                    }
                    @include media-breakpoint-only(md) {
                        height: 220px;
                    }

                    @include media-breakpoint-down(sm) {
                        height: 200px;

                    }

                    img {
                        max-width: 100%;
                        max-height: 100%;
                        display: block;
                        margin: 0 auto;
                    }

                }
            }

            .content {
                text-align: left;
                padding: 10px;

                .product {
                    height: 60px;
                    overflow: hidden;

                }
                .reviews {
                    float: left;
                    .bi {
                        &::before {
                            color: orange;
                        }
                    }
                }
                .brand-logo-cont {
                    float: right;
                    @media (max-width: 1199px) {
                        width:150px;
                        clear:both;
                        margin:auto
                    }
                    /*
                    @include media-breakpoint-down(lg) {
                        width:150px;
                        clear:both;
                        margin:auto;
                    }*/
                }

                .btn {
                    width: 100%;
                }
            }
        }
    }
}