footer {
  background-color: #222325;
  padding: rem(60, 20, 0, 20);

  .shopperlink {
    margin-top: 40px;
    // mobile
    @include media-breakpoint-down(sm) {
      margin-bottom:30px;
      margin-top: 0;
    }
  }

  .break-cont {
    // at breakpoint 1212px set the width to 100%
    @include media-breakpoint-down(xl) {
      width: 100%;
      flex: 0 0 auto;
    }
  }

  .logos {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    &-item {
      margin-bottom: rem(20px);
      .logo {
        max-width: 200px;
        margin-top: rem(10);
        padding-right: 2.5rem;
        @include media-breakpoint-down(lg) {

        }
        @include media-breakpoint-down(sm) {
          padding-right: 0;
          margin-top: 0;
        }
      }
    }
  }
  .shopper-approved {
    // on desktop only
    @media (min-width: 992px) {
      position: relative;
      left: -30px;
    }
  }
  .title {
    @extend .font-primary-1;
    font-size: rem(18);
    line-height: 1.2em;
    color: $color_neutral;
    margin: rem(5, 0);
  }
  .links {
    @extend .font-secondary-2;
    list-style: none;
    margin: 0;
    padding: 0;
    font-size: 14px;
    li {
      padding: 2px 0;
      a {
        color: $color_neutral;
      }
    }
  }
  .links-social {
    display: flex;
    align-items: center; /* This vertically centers the items if needed */
    gap: 30px; /* This creates space between the items */
    margin-bottom: 10px;
    li {
      padding: 2px 0;
      a {
        color: $color_neutral;
      }
    }
  }
  .company {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    color: $color_neutral;
    .navbar-brand {
      margin-right: rem(20);
      .logo {
        max-width: 200px;
      }
      @include media-breakpoint-down(lg) {
        //display: none;
      }
    }
    &-information {
      .title {
       // @extend .font-primary-1, .-variant-3;
      }
    }
  }
  .menu {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding-top: rem(10);
    flex-wrap: wrap;
    &-item {
      margin-bottom: rem(15px);
      &:not(:last-child) {
        margin-right: rem(15px);
      }
    }
  }
}
#footer_copyright {
  font-size: 1rem;
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 20px;
  background: #0b0b0b;
  flex-wrap: wrap;
  .logos {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    &_item {


      &:not(:last-child) {
        margin-right: rem(20);
        margin-bottom: 5px;
      }

      &.credit {
        margin-bottom: 0;
        margin-top: 3px;
        @include media-breakpoint-down(sm) {
          margin-top: 20px;
        }
      }
      &.ssl {
        @include media-breakpoint-down(sm) {
          margin-top: 20px;
        }
      }
    }
  }
}