#shopper_review_page {
  #review_header {
    padding-left:0;
    .pr_link {
      background-color: $color_secondary;
      padding: 4px 10px;
      font-size: 14px;
    }
  }
}
