#latest-news {
    padding: rem(60, 30);
    margin-bottom: rem(60);
    .latest-news-container {
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        max-width: rem(1320px);
    }
    .header {
        text-align: center;
        .title {
            @extend .font-primary-1;
            font-size: rem(72);
            color: $color_secondary;
            line-height: rem(72);
            &:after {
                content: "";
                display: block;
                background-color: $color_primary;
                width: rem(130);
                height: rem(4);
                margin: rem(35, 0);
                text-align: center;
                position: relative;
                left: 50%;
                transform: translateX(-50%);
            }
        }
        .lead {
            @extend .font-secondary-3;
            margin-bottom: rem(80);
        }
    }
    .news {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
        &-container {
            flex: 0 1 32%;
            box-shadow: rem(0px, 1px, 7px, 2px) #ccc;
            @include media-breakpoint-down(lg){
                flex: 1 0 100%;
                margin-bottom: rem(20px);
            }
        }
        &-item {
            position: relative;
            &-header {
                .date {
                    @extend .font-primary-1, .-variant-1;
                    font-style: italic;
                    line-height: 1em;
                    position: absolute;
                    top: rem(15);
                    left: rem(15);
                    color: $color_neutral;
                    z-index: 1;
                    .month-year {
                        font-size: rem(18);
                        line-height: 1em;
                        text-transform: uppercase;
                    }
                }
                figure {
                    position: relative;
                    overflow: hidden;
                    height: 300px;
                    &:after {
                        content: '';
                        display: block;
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        background-color: #0000005c;
                        top: 0;
                        left: 0;
                    }
                    img {
                        position: absolute;
                        height: 100%;
                        width: 100%;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        max-width: none;
                        object-fit: cover;
                    }
                }
            }
            &-body {
                padding: rem(34);
                .title {
                    @extend .font-primary-1;
                    font-size: rem(24);
                    font-style: italic;
                    margin-bottom: rem(30);
                    line-height: 1em;
                }
                .description {
                    @extend .font-secondary-2, .-variant-1;
                    margin-bottom: rem(5);
                }
            }
        }
    }
}



@include media-breakpoint-only(md) {
    #latest-news {
        .header {
            .title {
                font-size: rem(58);
            }
        }
        .news {
            &-item {
                margin-bottom: rem(15);
                figure {
                    position: relative;
                    height: 400px;
                    overflow: hidden;
                    .img-fluid {
                        max-width: 100%;
                        width: 100%;
                        height: auto;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                    }
                }
            }
        }
    }
}
@include media-breakpoint-only(sm) {
    #latest-news {
        .header {
            .title {
                font-size: rem(58);
            }
        }
        .news {
            &-item {
                margin-bottom: rem(15);
            }
        }
    }
}

@include media-breakpoint-only(xs) {
    #latest-news {
        padding: rem(15);
        .header {
            .title {
                font-size: rem(40);
                line-height: rem(40);
            }
        }
        .news {
            &-item {
                margin-bottom: rem(15);
            }
        }
    }
}