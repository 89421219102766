#category-homepage {
  background-color: $color_secondary;
  margin-bottom: rem(40);

  .featured {
    padding-bottom: 4rem;
  }

  .header {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    //background: url('../images/backgrounds/mountains-pattern-transparent-wide.png') center bottom;
    background-size: auto;
    background-repeat: repeat-x;
    background-color: $color_secondary;
    color: $color_neutral;
    margin-bottom: rem(25px);
    .title {
      padding: rem(120, 0, 30, 0);
      letter-spacing: rem(-4px);
      line-height: rem(75px);
            &:after {
                // content: "";
                // display: block;
                // background-color: $color_primary;
                // width: rem(200px);
                // height: rem(1px);
                // margin: rem(30px) auto 0;
            }
            &-top {
                @extend .font-primary-1, .-variant-1;
                white-space: nowrap;
                font-size: rem(60px);
                @include media-breakpoint-down(md) {
                  font-size: rem(30px);
                  line-height: rem(30px);
                  letter-spacing: -.1rem;
                }
            }
            &-bottom {
                @extend .font-primary-1, .-variant-2;
                //white-space: nowrap;
                white-space: normal !important;
                text-transform: uppercase;
                font-size: rem(90px);
                @include media-breakpoint-down(lg) {
                  font-size: rem(38px);
                  line-height:rem(40px);
                  white-space: normal !important;
                }
                @include media-breakpoint-down(md) {
                  font-size: rem(38px);
                  line-height:rem(40px);
                  white-space: normal !important;
                }
            }
    }
    .paragraph-lead {
      @extend .font-secondary-2, .-variant-1;
      max-width: rem(880px);
      padding-bottom: rem(50px);
    }
  }

  .grid {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: repeat(2, 1fr);
      grid-column-gap: 10px;
      grid-row-gap: 0px;
      @include media-breakpoint-down(lg) {
        grid-template-columns: repeat(1, 1fr);
        grid-template-rows: repeat(5, 1fr);
        grid-row-gap: 15px;
      }

      .grid-item-1 {
        background: #fff;
        grid-area: 1 / 1 / 3 / 2;
        height:rem(700px);
        img {
          right: -90%;
        }
        @include media-breakpoint-down(lg) {
          grid-area:auto;
          img {
            right: -30%;
          }
        }
      }
      .grid-item-2 {
        background: #fff;
        grid-area: 1 / 2 / 2 / 4; height:rem(345px); margin-bottom: rem(10px);
        img {
          right: 0;
          height: 180%;
          top: -35%;
          left: 0;
        }
        @include media-breakpoint-down(lg) {
          grid-area:auto;
          height: auto;
          img {
            right: 0;
            height: 130%;
            top: -20%;
            left: -50%;
          }
        }
      }
      .grid-item-3 {
        background: #fff;
        grid-area: 2 / 2 / 3 / 4; height:rem(345px);
        img {
          right: 0;
          top: -30%;
         // @include media-breakpoint-up(xl) {
            width: 100%;
            height: auto;
        //  }
        }
        @include media-breakpoint-down(lg) {
          grid-area:auto;
          height: auto;
          img {
            top: 15%
          }
        }
      }
      .grid-item-4 {
        background: #fff;
        grid-area: 1 / 4 / 3 / 5; height:rem(700px);
        img {
          left: -145%;
        }
        @include media-breakpoint-down(lg) {
          grid-area:auto;
          height: auto;
          img {
            left: -50%;
            height: 80%;
            top: 10%;
          }
        }
      }
    .grid-item-5 {
      background: #fff;
      /* Starts at row 3, column 1, ends at row 4, spans all columns to column 5 */
      grid-area: 3 / 1 / 4 / 5;
      height: rem(345px); /* Assuming rem is a defined function, otherwise use a fixed value like 700px */
      img {
        height: 300%;
        top: -110%;
        left: 0;
      }
      @include media-breakpoint-down(lg) {
        grid-area: auto;
        height: auto;
        img {
          height: 100%;
          top: 0;
          left: -40%;
        }
      }
    }

    [class^="grid-item"] {
        overflow: hidden;
      }
      .category_card {
        position: relative;
        height: 100%;
        &_img {
          position: absolute;
          height: 100%;
          transition: all 0.4s ease-out;
        }
        &_caption {
          position: absolute;
          color: white;
          text-decoration: underline;
          text-transform: uppercase;
          top: rem(10px);
          left: rem(10px);
          font-family: $font-family-secondary;
          font-size: rem(18px);
          font-weight: 500;
          text-underline-offset: rem(5px);
          z-index:2;
        }
        &_overlay {
          position: absolute;
          background-image: linear-gradient(to bottom right, #000, #000, $color_primary);
          display: inline-block;
          width: 300%;
          height: 100%;
          left:0;
          opacity: .5;
          z-index: 1;
          transition: all 0.4s ease-out;
          &:hover {
            left: 100%;
            transform: translate(-100%, 0);
            & + .category_card_img {
              transform: scale(1.2);
            }
          }
        }
      }
  }
}

@include media-breakpoint-down(xxl) {

}
@include media-breakpoint-only(md) {

}
@include media-breakpoint-only(sm) {

}
@include media-breakpoint-only(xs) {

}