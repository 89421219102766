#generations-selector {
  padding: .125rem .25rem 6.25rem .25rem;

  h2 {
    font-size: 1.5rem;
    text-align: center;
    font-weight: 600;
    color: black;
  }

  @include media-breakpoint-down(md) {
    padding:0;
    padding-bottom:2rem;
  }

  .image-slider {
    .swiper-slide a {
      // border: 1px solid red;
      display: flex;
      flex-direction: column;
      align-items: center;
      //width: 330px; // fixed width

      .image {
        width: 80%;
        height: 230px;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;


        @include media-breakpoint-down(lg) {
          height: 210px;
        }
        @include media-breakpoint-down(md) {
          height: 170px;
        }

        @include media-breakpoint-down(sm) {
          height: 70px;

        }
      }

      .image-caption {
        margin-top: 10px;
        font-size: 1.1rem;
        font-weight: 600;
        text-align: center;

        color: $color_primary;

        @include media-breakpoint-down(sm) {
          font-size: .8rem;

        }
        // additional styling for caption
      }
    }

    .swiper-pagination {
      margin-bottom:30px;
    }

    // Swiper navigation button styling
    .swiper-button-next, .swiper-button-prev {
      position: absolute;
      top: 210px; // Adjust this value as needed
      color: #000;
      // additional styling for buttons
    }

    .swiper-button-next {
      right: 10px; // Adjust this value as needed
    }

    .swiper-button-prev {
      left: 10px; // Adjust this value as needed
    }

    // at 992 and below
    @include media-breakpoint-down(lg) {
      .swiper-button-next, .swiper-button-prev {
        top: 190px; // Adjust this value as needed
      }
      .swiper-pagination {
        margin-bottom:40px;
      }
    }

    // at 768px and below
    @include media-breakpoint-down(md) {
      .swiper-button-next, .swiper-button-prev {
        top: 150px; // Adjust this value as needed
      }
      .swiper-pagination {
        margin-bottom:65px;
      }
    }

    @include media-breakpoint-down(sm) {
      .swiper-button-next, .swiper-button-prev {
        top: 130px; // Adjust this value as needed
        --swiper-navigation-size: 20px; /* Smaller size for mobile */
      }
      .swiper-pagination {
        margin-bottom:35px;
      }
    }

  }

}