@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:opsz,wght@6..12,300;6..12,400;6..12,600;6..12,700;6..12,800&family=Roboto&display=swap');
@import "overrides/boostrap";
@import "~bootstrap-icons/font/bootstrap-icons.css";
@import "~animate.css/source/_base.css";
@import "~animate.css/source/_vars.css";
@import "~animate.css/source/sliding_entrances/slideInDown.css";
@import "~animate.css/source/sliding_entrances/slideInUp.css";
@import "base/functions";
@import "base/fonts";
@import "base/text";
@import "variants/colors";
@import '@fancyapps/fancybox/dist/jquery.fancybox.min.css';

//overrides
//@import "overrides/parts-square";
//@import "overrides/parts-page";
//@import "overrides/checkout";
//@import "overrides/forms";


//components
@import "components/layout";
@import "components/slick";
@import "components/header";
@import "components/header-menu";
@import "components/mobile-menu";
@import "components/homepage";
@import "components/footer";
@import "components/shopper_approved";
@import "components/loader";
@import "components/contact";


@import "components-old/popular-products";
@import "components-old/generations_selector";
@import "components-old/latest-news";
@import "components-old/blog";
@import "components-old/cms";
@import "components-old/ribbon";
@import "components-old/category";
@import "components-old/authorized-dealer";
//@import "components-old/hero";

//@import "components-old/vehicle-select";

/*@import "components/header";
@import "components/hero";
@import "components/category-grid";
@import "components/popular-products";
@import "components/vehicle-select";
@import "components/paybright";
@import "components/brands";
@import "components/latest-news";
@import "components/social"; 
@import "components/footer";
@import "components/newsletter";
@import "components/blog";
@import "components/cms";
@import "components/contact";
@import "components/about";
@import "components/ribbon";
*/

/*
body {
    @extend .font-secondary-2, .-variant-1;
    color: $color_secondary;
    a {
        color: $color_primary_dark;
        &:hover {
            color: $color_primary;
        }
    }
}
figure {
    margin: 0;
}
.link {
    color: $color_primary_darker;
    &:hover {
        color: $color_primary;
    }
}
.container-fluid-p0 {
    @include make-container(0);
}

.link-brand {
    @extend .font-secondary-1;
    display: inline-block;
    border-left: 2px solid #ff0000;
    border-right: 2px solid #ff0000;
    padding-left: rem(20px);
    padding-right: rem(20px);
    margin-left: rem(8px);
    margin-right: rem(8px);
    transform: skew(-25deg, 0);
    .nav-link {
        padding:0;
        color: $color_primary;
        transform: skew(25deg, 0);
    }
    @include media-breakpoint-down(lg) {
        font-size: rem(10px);
        padding: rem(0, 10px);
    }
}
 */


.modal-backdrop.show {
    opacity: 0.8;

}

.grecaptcha-badge {
    visibility: hidden !important;;
}

pre {
    font-family: inherit;
    font-size: .875rem; /* Or any size that fits your design */
    white-space: pre-wrap; /* Allows text to wrap */
    word-wrap: break-word; /* Break long words if needed */

    &.product-list {
        font-size: 1rem;
    }
}


.main-content {

  padding-top: 150px;
  max-width: 1366px;
  margin: auto;


    @include media-breakpoint-down(md) {
        padding-top: 0;
    }
    @include media-breakpoint-only(xs) {
        padding: 0;
    }
    &.-sidebar {
        display: flex;
        flex-direction: row;
        @include media-breakpoint-down(lg) { 
            flex-direction: column;
        }
    }
    &-section {
        flex: 1 1 auto;
        padding: rem(0, 10px);
        @include media-breakpoint-down(lg) { 
            order: 1;
        }
    }
    &-sidebar {
        flex: 1 1 auto;
        min-width: rem(220px);
        padding: rem(0, 10px);
        @include media-breakpoint-down(lg) { 
            order: 0;
        }
        .sidebar-title {
            @extend .font-primary-3;
        }
        .link {
            display: inline-flex;
            flex-direction: row;
            font-size: 20px;
            margin-bottom: 30px;
            align-items: center;
            @include media-breakpoint-up(lg) { 
                display: none;
            }
            &:before {
                order: 1; 
            }
        }
        .sidebar {
            list-style: none;
            padding-left: 0;
            @include media-breakpoint-down(lg) { 
                display: none;
            }
            &-item {
                border-top: rem(1px) dotted $color_neutral_2;
                padding: rem(20px, 0, 0);
                &:not(:first-child) {
                    margin: rem(20px, 0, 0);
                }
                &_close {
                    border: none;
                    padding: 0;
                    @include media-breakpoint-up(lg) { 
                        display: none;
                    }
                    .link {
                        margin-bottom: 0;
                        display: flex;
                        justify-content: flex-end;  
                    }
                }
            }
            &-links {
                $a:&;
                list-style: none;
                padding: rem(10px, 0);
                &-item {
                    @extend .font-secondary-1, .-variant-1;
                    display: flex;
                    font-size: rem(12px);
                    text-transform: none;
                    padding: rem(5px, 0);
                }
                &.-variant-1 {
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    .sidebar-links-item {
                        padding: rem(5px); 
                        font-size: rem(14px);
                    }
                }
            }
            &.show {
                display: block;
                position: fixed;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                z-index: 100001;
                background: $color_neutral_1;
                padding: 20px;
                margin-bottom: 0;
                overflow: auto;
            }
        }
    }
}

