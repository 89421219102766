#footer-contact {
    @extend %contact;
    .contact & {
        display: none;
    }

    .form-label {
        color: $color_primary_dark;
        opacity: 0;
    }
}

#contactPage {
    @extend %contact;
    .contact & {
    background: white;
    padding: 20px 0px 100px;
    z-index: 999;
    color: black;
    &:before {
        display: none;
    }
    .contact_info,
    .contact_form {
        background: none;
        padding: 0;
    }
}
}


%contact {
    position: relative;
    padding: rem(0px, 0px, 50px, 0px);
    //margin: -2.5rem 0 2.5rem;
    //margin-bottom: 0;
    background: url('../images/hero/mustang-hero.png') 50% 70% no-repeat;
    background-repeat: no-repeat;
    background-size: cover;
    @include media-breakpoint-down(lg) {
        padding-left: 0;
        padding-right: 0;
    }
    &:before {
        width: 0;
        height: 0;
        border-left: 100vw solid transparent;
        content: "";
        border-bottom: 6vw solid $color_neutral;
        transform: rotate(180deg);
        position: relative;
        z-index: 0;
        display: block;
        padding-bottom: 50px;
    }

    .contact {
        &_header {
            text-align: center;
            &_title {
                @extend .font-primary-1, .-variant-1;
                &:after {
                    content: "";
                    display: block;
                    background-color: $color_primary;
                    width: rem(175);
                    height: rem(1);
                    margin:rem(20) auto rem(40);
                }
            }
            &_lead {
                @extend .font-secondary-2, .-variant-1;
                font-size: rem(18px);
                // @extend .font-secondary-3;
                margin-bottom: rem(80);
            }
        }
        &_grid {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: 1fr;
            grid-column-gap: 20px;
            grid-row-gap: 0px;
            @include media-breakpoint-down(lg) {
                grid-template-columns: repeat(1, 1fr);
            }
        }
        &_info {
           // background: rgb(0 0 0 / 70%);
            padding: 30px;
            &_group {
                font-family: $font-family-secondary;
                font-size: rem(16px);
                font-weight: 300;
                padding: 0;
                list-style: none;
                @include media-breakpoint-down(lg) {
                    font-size: rem(14px);
                }
                li {
                    display: flex;
                    margin-bottom: rem(5px);
                    padding: 0;
                    list-style: none;
                    gap: 5px;
                    .bi {
                        margin-right: rem(10px);
                    }
                }
            }
            &_title {
                font-family: $font-headers;
                font-weight: 700;
                font-size: rem(28);
                margin-bottom: rem(30);
            }
        }
        &_form {
            //background: rgb(0 0 0 / 70%);
            padding: 30px;
            &_title {
                font-family: $font-headers;
                font-weight: 700;
                font-size: rem(28);
                margin-bottom: rem(30);
            }
            &_fields {
                font-family: $font-family-secondary;
                font-size: rem(16px);
                font-weight: 300;
                .btn {
                    font-family: $font-family-secondary;
                    font-size: rem(16px);
                    font-weight: 500;
                    padding: rem(10px, 20px);
                }
            }
            input,
            textarea {
                //background: rgb(0 0 0 / 80%);
                border-color: #303030;
                color: $color_neutral_2;
                padding: rem(14px, 12px);
            }
        }
    }

}

.body-wrap {
    &.products {
        &+ #contact:before {
            border-bottom-color: white;
        }
    }
}