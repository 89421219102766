/**/
/* mobile nav */
/**/

.mobile-nav-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  overflow: visible;
  width: rem(300);
}

.mobile-nav {
  visibility: hidden;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  //width: rem(444);
  width: rem(300);
  overflow: visible;

  background-color: #FFF;
  box-shadow: 0 0 0.25rem rgba(0, 0, 0, 0.2), 0 0 0 1000rem rgba(0, 0, 0, 0.5);
  pointer-events: none;
  opacity: 0;
  transform: translateX(0%);
  transition-duration: 0.375s;


  nav {
    overflow-y: scroll;
    height: 100%;
  }

  .nav-item {

    .dropdown-menu {
      padding: 0;
    }


    .dropdown-item {
      text-transform: uppercase;
      transform: translateX(0%);
      transition: all .3s ease;
      border-bottom: 1px solid #ebebeb;
      background-color: #fff;
      font-size: .9rem;
      @extend .font-secondary-1;
      padding: 12px 20px 10px;
      cursor: pointer;

      i.main {
        margin-right: 5px;
        position: fixed;
        right: 10px;
      }


    }

    .dropdown-submenu {
      position: fixed;
      top: 0;
      left: 0;
      bottom: 0;
      transform: translateX(0%);
      transition-duration: 0.375s;
      width: rem(300);
      overflow-y: auto;

      /* .category-name {
         text-transform: uppercase;
         transition: all .3s ease;
         border-bottom: 1px solid #ebebeb;
         background-color: #f8f8f8;
         @extend .font-secondary-1;


       }*/

      .dropdown-item-back {
        text-transform: uppercase;
        transition: all .3s ease;
        border-bottom: 1px solid #ebebeb;
        background-color: #f8f8f8;
        @extend .font-secondary-1;
        font-size: 1.0rem;
        text-align: center;

        i {
          margin-right: 5px;
          position: fixed;
          left: 10px;
        }
      }


      .browse-all-cont {

        text-align: center;
        margin: auto;
        margin-top: rem(20);

        .browse-all {
          background-color: #b20000;
          width: fit-content;
          line-height: 1;
          text-align: center;
          vertical-align: middle;
          text-decoration: none;
          padding: 12px 15px 11px;
          border: 1px solid transparent;
          border-radius: 3px;
          box-shadow: 0 1px 3px #0000001a;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          color: #fff;
          display: inline-block;
        }
      }

    }


  }


}

.mobile-menu-padder {
  margin-top: rem(20);
}

.mobile-nav_active {
  pointer-events: auto;
  opacity: 1;
  visibility: visible;
  transform: none;
}

.mobile-nav::after {
  content: '';
  display: block;
  height: 1rem;
}


.mobile-nav-parent {
  position: relative;
  /* Add any other styles for the parent element here */
}


.mobile-close-wrapper {
  position: relative;
}

.mobile-close-fixed-wrapper {
  position: absolute;
  top: 0;
  right: -44px;
  width: 44px;
  height: 45px;
  z-index: 10000;
}
/*
.mobile-nav__close {

  width: 50px;
  height: 50px;
  z-index: 10000;
  font-size: 30px;
  line-height: 45px;
  position: fixed;
  text-align: center;
  color: #fff;
  pointer-events: auto;
  background-color: #b0241f;
  font-family: Arimo, sans-serif;
}


.mobile-nav__close i {
  color: white;
  width: 1.3rem;
  line-height: 3rem;
}*/


.mobile-nav__close {
  width: 45px;
  height: 45px;
  z-index: 10000;
  font-size: 30px;
  line-height: 45px;
  position: fixed;
  text-align: center;
  color: #fff;
  pointer-events: auto;
  background-color: #b0241f;
  font-family: Arimo, sans-serif;
  left: rem(300);
}

.mobile-nav__close i {
  color: white;
  width: 1.3rem;
  line-height: 3rem;
}


.mobile-nav__hr {
  margin: .5rem 1.25rem;
  border: none;
  border-top: 1px solid #F4EFED;
}

.mobile-nav__link {
  display: block;
  padding: 0 1.25rem;
  font-size: 0.875rem;
  line-height: 2.75rem;
  font-weight: 500;
  text-transform: uppercase;
}

.mobile-nav__link_sec {
  line-height: 2.25rem;
  font-size: .8rem;
  color: #9D9A9A;
}

.mobile-nav__link_all {
  color: #234476;
}

.mobile-nav__link_active {
  background-color: rgba(35, 68, 118, 0.15);
}
