.btn {
    @extend .font-secondary-1,.-variant-1;
    color: $color_neutral;
    border: none;
    box-shadow: none;
    &:hover,
    &:focus {
       color: $color_neutral;
        box-shadow: none;
    }
}

