.nav-tabs {
  border-bottom: none;
  .nav-link {
    color: $color_primary_dark;
    &.active {
      border-bottom: rem(1) solid $color-neutral_4;
      background-color: $color-neutral_4;
      color: $color_primary_darker;
      .bi {
        &:before {
          transition: .25s ease-in-out;
          transform: rotate(90deg);
        }
      }
    }
  }
}
.tab-content {
  .tab-pane {
    background: $color-neutral_4;
    border-top: rem(1) solid $color_neutral_4;
    border-bottom: rem(1) solid $color_neutral_3;
    border-left: rem(1) solid $color_neutral_3;
    border-right: rem(1) solid $color_neutral_3;
    border-radius: rem(0,0,4,4);
    padding: rem(10);
  }
}