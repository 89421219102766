/* === Import Bootstrap functions and variables === */
@import "node_modules/bootstrap/scss/functions";
@import "../variants/colors";
/*-------------------------------------------------------------------*/
/* Change theme-colors */
$theme-colors: (
    primary: $color_primary,
    secondary: #3b3c3e,
    success: #1e6111,
    info:  #64c3d2,
    warning: #f5e74a,
    danger: #820410
);

$border-radius:2px;

$input-btn-focus-color: $color_primary;
$input-btn-focus-width: 2px;
$input-focus-border-color:$color_primary;

@import "node_modules/bootstrap/scss/variables";