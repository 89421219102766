
  
  .loader {
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    min-height: 300px;

    // if not mobile
    @include media-breakpoint-up(md) {
        //margin-left:rem(-500);
      margin-left:-50%;
    }



    .ring {
        position: absolute;
        width: 200px;
        height: 200px;
        border-radius: 50%;
        animation: ring 2s linear infinite;
        &:before {
            position: absolute;
            content: '';
            left: 0;
            top: 0;
            width: 100%;
            border-radius: 50%;
            box-shadow: 0 0 5px rgba(255, 255, 255, .3);
          }
      }
      
      span {
        color: #fff;
        font-size: 18px;
        text-transform: capitalize;
        letter-spacing: 1px;
        line-height: 200px;
        animation: text 3s ease-in-out infinite;
      }
  }
  
  @keyframes ring {
    0%{
       transform:rotate(0deg);
       box-shadow: 2px 10px 4px #fff;
    }
  
    50%{
        transform:rotate(180deg);
        box-shadow: 2px 10px 4px #973200;
    }
  
    80%{
         transform:rotate(288deg);
         box-shadow: 2px 10px 4px $color_primary_lighter;
    }
    100%{
      transform:rotate(360deg);
      box-shadow: 2px 10px 4px #fff;
    }
  }
  
  @keyframes text {
    50% {
      color: $color_primary_lighter;
    }
  }

//HTML to use
// <div class="loader">
//   <div class="ring"></div>
//  <span>Searching Parts...</span>
// </div>