
.home-brands {
  width:100%;
  background-color: $color_secondary;
  padding-bottom: 30px;
  padding-top: 10px;

  ul {
    margin-left:20px;
    margin-right:20px;
    list-style: none;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: row;
    li {
      padding: 20px 0px 0px;
    }
  }
}

.all-categories {
  .swiper {
    padding-bottom: 50px;
  }
}



.hero__cont {
  .vehicle-search {
    // remove background color
    background-color: transparent;
    margin-top:3rem;

    // on mobile
    @media (max-width: 767px) {
      margin-top: .2rem;
    }
  }
}

.categories-section {
  //margin-top: 3rem;

  .main-sectors__row {
    justify-content: center;
  }

  .main-sectors {
    margin-bottom:0;
  }

  .category-section {
    &.gray {
      background-color: #f5f5f5;
    }
    padding: 1rem;
  }

  .parts-card {
    background-color: #fff;
  }
}

.content-block-section {
    //padding: 1rem;
}
