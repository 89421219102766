$color_primary: #060ffc;
$color_primary_lighter: #c90000;
$color_primary_dark: #c90000;
$color_primary_darker: #f18d26;
$color_secondary: #373e46;
$color_secondary_darker: #111111;
$color_secondary_dark: #141618;
$color_secondary_light: #575757;
$color_secondary_lighter: #7c7c7c;
$color_neutral: #ffffff;
$color_neutral_1: #f0f0f0;
$color_neutral_2:#a7a7a7;
$color_neutral_3:#dbdbdb;
$color-neutral_4:#efefef;

$overlay-dark  :rgba($color_secondary, .8);