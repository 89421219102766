#blog {
    .blog-item {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: wrap;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        &:not(:last-child) {
            margin-bottom: 80px;
            @include media-breakpoint-down(md){
                margin-bottom: 40px;
            }
        }
        @include media-breakpoint-down(md){
            flex-wrap: wrap;
        }
        &-meta {
            flex: 0 0 auto;
            margin-right: 45px;
            text-align: right;
            @include media-breakpoint-down(md){
                flex: 0 0 auto;
                order: 1;
                margin-right: 15px;
            }
            .entry & {
                @include media-breakpoint-down(sm){
                    flex: 1 0 100%;
                    margin-bottom: 30px;
                    text-align: left;
                }
            }
            .date-format {
                display: flex;
                flex-direction: column;
                border-bottom: 1px dotted $color_neutral_2;
                margin-bottom: 15px;
                padding-bottom: 15px;
                .day {
                    @extend .font-primary-1,.-variant-1;
                    line-height: 1em;
                }
            }
            .author-links {
                font-size: 12px;
            }
            .cat-links {
                font-size: 12px;
            }
        }
        &-image {
            height: rem(400px);
            flex: 1 1 70%;
            overflow: hidden;
            position: relative;
            margin-right: 15px;
            @include media-breakpoint-down(xxl){
                height: rem(300px);
            }
            @include media-breakpoint-down(lg){
                height: rem(250px);
            }
            @include media-breakpoint-down(md) {
                order: 0;
                margin-right: 0;
                margin-bottom: 15px;
                height: 200px;    
                flex: 1 1 100%;
            }
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                position: absolute;
                top:50%;
                transition: 0.4s ease-in-out;
                transform: scale(1) translateY(-50%);
                &:hover {
                    transition: 0.4s ease-in-out;
                    transform: scale(1.2)  translateY(-50%);
                }
            }
        }
        &-description {
            flex: 0 1 auto;
            align-self: center;
            margin-left: rem(135px);
            margin-top: rem(30px);
            .entry & {
                flex: 1 1 50%;
                margin-left: 0;
                margin-top: 0;
                img {
                    height: auto;
                    max-width: 100%;
                }
            }
            @include media-breakpoint-down(md){
                flex-wrap: wrap;
                order: 2;
                flex: 1;
                margin-left: 10px;
                margin-top: 0;
            }
            .title {
                @extend .font-primary-1,.-variant-1;
                line-height: 1em;
                margin-bottom: 15px;
                overflow: hidden;                
                @include media-breakpoint-down(md){
                    font-size: 40px;
                }
            }
            p {
                overflow: hidden;
                @include media-breakpoint-only(xxl) {
                    max-height: 190px;
                }
                @include media-breakpoint-down(xxl) {
                    max-height: 120px;
                }
                .entry & {
                    @include media-breakpoint-only(xxl) {
                        max-height: none;
                    }
                    @include media-breakpoint-down(xxl) {
                        max-height: none;
                    }
                }
            }
            .link {
                font-size: 14px;
            }
        }
    }
}

