#authorized-dealer {
  padding: rem(40px, 0);

  .title {
    @extend .font-primary-1;
    position: relative;
    font-size: rem(18px);
    text-transform: uppercase;
    color: $color_secondary_light;
    padding-bottom: rem(10px);
    border-bottom: rem(2px) solid $color_secondary_light;
    margin-bottom: rem(15px);

    &:before {
      content: '';
      position: absolute;
      width: rem(210px);
      height: rem(2px);
      background: $color_primary;
      bottom: rem(-2px);
    }
  }

  .brand-logo {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    img {
      margin: 30px;
    }

    /*.brand-logo-item {
      margin-bottom: rem(15px);
      &:not(:last-child) {
        margin-right: rem(15px);
      }
    }*/
  }

  .paragraph-lead {
    @extend .font-secondary-2, .-variant-1;
    padding-bottom: rem(10px);
  }

}